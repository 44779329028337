var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //
          'id',
          'from',
          'subject',
          'xml_content', // TODO: JSON viewer or string ...
          //'is_valid',
          Object.assign({}, this.helpers.validIcons, {key: 'is_valid'}),
          'invalid_statuses',
          Object.assign({}, _vm.helpers.createdAt, {label: 'Processed'}),
          { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 0; display: none;' }
        ],"config":{ url: 'admin/gmail/parsed', name: 'parsed', lname: 'parsed', _active: false, noCreate: true, noOptions: true/*, parseItems*/ },"columnFilter":true}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }