<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //
            'id',
            'from',
            'subject',
            'xml_content', // TODO: JSON viewer or string ...
            //'is_valid',
            { ...this.helpers.validIcons, key: 'is_valid' },
            'invalid_statuses',
            { ...helpers.createdAt, label: 'Processed' },
            { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 0; display: none;' }
          ]"
          :config="{ url: 'admin/gmail/parsed', name: 'parsed', lname: 'parsed', _active: false, noCreate: true, noOptions: true/*, parseItems*/ }"
          :columnFilter="true"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'GMailParsed',
  mixins: [crudMixin],
  methods: {
    parseItems(parent, items) {
      return items.map(item => {
        if (item.xml_content) item.xml_content = JSON.stringify(item.xml_content, null, 2)
        return item
      })
    }
  }
}
</script>
